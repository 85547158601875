import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLoading } from '../components/LoadingProvider';
import { enqueueSnackbar } from 'notistack';
import { ToDoListProps } from './ToDoList';
import userService from '../services/UserService';
import orgService from '../services/OrgService';
import KeycloakService from '../services/KeycloakService';
import { ProfileCompleteProps } from '../utilities/HelperFunctions';



export interface OrgPurlProps{
    orgPurl: string;
    setOrgPurl: React.Dispatch<React.SetStateAction<string>>;
}

export interface SSNRequiredProps{
    ssnRequired: string;
    setSsnRequired: React.Dispatch<React.SetStateAction<string>>;
}

export interface FormUUIDProps{
    formUuid: string;
    setFormUuid: React.Dispatch<React.SetStateAction<string>>;
}

interface SearchParamHandlerProps {
    todoListProps: ToDoListProps;
    orgPurlProps : OrgPurlProps;
    ssnRequiredProps : SSNRequiredProps;
    formUuidProps : FormUUIDProps;
    profileCompleteProps: ProfileCompleteProps
    processParams: boolean;
}

const SearchParamHandler: React.FC<SearchParamHandlerProps> = (props:SearchParamHandlerProps) => {
    const [searchParams] = useSearchParams();
    const { setLoading } = useLoading();
    

    const handlePurl = (orgPurl: string) => {
        //logged in
        if (KeycloakService.isLoggedIn()) {
            if(props.profileCompleteProps.profileComplete && props.processParams){
                userService.AssignToOrg(orgPurl, KeycloakService.getId(), setLoading);
            }
            props.orgPurlProps.setOrgPurl(orgPurl);

            if (orgPurl) {
                orgService.GetSSNRequired(orgPurl, setLoading).then((ssnRequired) => {
                    props.ssnRequiredProps.setSsnRequired(ssnRequired);
                });
            } 
        }
        //not logged in, this might not be needed anymore since we're sending them to keycloak instead.
        else {
            orgService.CheckForValidPURL(orgPurl, setLoading).then((isValid) => {
                if (!isValid) {
                    enqueueSnackbar("Unable to process account. Please use the requested link provided by your school", { variant: "error" })
                }
            })
        }
    }
    const handleFormUuid = (formUuid : string, orgPurl: string) => {
        if (KeycloakService.isLoggedIn()) {
            if (props.profileCompleteProps.profileComplete && props.processParams) {
                userService.AssignFormPurlToUser(formUuid, orgPurl, KeycloakService.getId(), setLoading).then((isValid) => {
                    props.todoListProps.setIsToDoListDataLoaded(false);
                });
            }
            props.formUuidProps.setFormUuid(formUuid);
        }

    }
    useEffect(() => {
        const orgPurl = searchParams.get("purl") || null;
        const formUuid = searchParams.get("formUuid") || null;
        if (orgPurl) {
            handlePurl(orgPurl);
        }
        if (formUuid && orgPurl) {
            handleFormUuid(formUuid, orgPurl);
        }
    }, [searchParams,props.profileCompleteProps.profileComplete,props.processParams]);

    return (
        null
    )
};

export default SearchParamHandler;


